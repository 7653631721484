import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react'

// mobx
import { observer } from 'mobx-react'

import { Button, Container, Header, Input, Message } from 'semantic-ui-react'

const emailValidatorRegex =
  /^[\w.!#$%&'*+/=?^`{|}~-]+@[^_\W]([a-z\d-]{0,61}[^_\W])?(\.[^_\W]([a-z\d-]{0,61}[^_\W])?)*$/i

export const RegisterWithEmail = observer(() => {
  const [loading, setLoading] = useState(false)
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const [submitSuccessful, setSubmitSuccessful] = useState(false)
  const [email, setEmail] = useState('')
  const [needsAssistance, setNeedsAssistance] = useState(false)
  const emailEverSet = useRef(false)
  const successMessage = 'Thank you! Your registration has been submitted successfully. Lyniate team will get in touch with you in 2 working days.'
  const [failureMessage, setFailureMessage] = useState('')

  const isEmailValid = useMemo(
    () => emailValidatorRegex.test(email),
    [email]
  )
  const onChangeEmailAddress = useCallback(
    (_event, { value }) => setEmail(value),
    []
  )
  const onClickRegister = async () => {
    setLoading(true)
    setEmailSubmitted(false)
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    onRegister(email, token)
      .then(response => {
        if (response) {
          console.log(response)
        }
        setEmail('')
        setSubmitSuccessful(true)
        setEmailSubmitted(true)
      }, rejectReason => {
        setFailureMessage(rejectReason.toString())
        setSubmitSuccessful(false)
        setEmailSubmitted(true)
      })
      .catch(error => {
        setFailureMessage(error.toString())
        setSubmitSuccessful(false)
        setEmailSubmitted(true)
      })
      .finally(() => setLoading(false))
  }

  const onRegister = async (emailAddress, token) => {
    console.log('onConfirm Start ', emailAddress, token)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: emailAddress,
        marketplace_token: token
      })
    }
    const response = await fetch(
      process.env.REACT_APP_REGISTER_ENDPOINT,
      requestOptions)
    const data = await response.json()
    if (response.ok) {
      return data
    }
    const error = (data && data.message) || response.status
    return Promise.reject(error)
  }

  useEffect(() => {
    if (email) emailEverSet.current = true
    if (isEmailValid) {
      setNeedsAssistance(false)
    } else if (!needsAssistance && emailEverSet.current) {
      const timer = setTimeout(() => {
        setNeedsAssistance(true)
      }, 3000 /* ms */)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [email, needsAssistance])

  return (
    <Container style={{ padding: '100px 0', fontSize: 'medium' }}>
      <div hidden={submitSuccessful}>
        <Header as='h2'>Enter your email address to register</Header>

        <Message
          hidden={
            !needsAssistance ||
            isEmailValid ||
            loading
          }
          warning>
          Please enter a valid email address.
        </Message>
        <Input
          placeholder='Email address'
          value={email}
          onChange={
            onChangeEmailAddress
          }
          disabled={loading}
          style={{ width: '100%', maxWidth: '640px', margin: '1em 1em 1em 0' }}
        />

        <Button
          positive
          disabled={!isEmailValid}
          loading={loading}
          onClick={onClickRegister}>
          Register
        </Button>
      </div>
      <Message
        hidden={loading || !emailSubmitted}
        success={submitSuccessful}
        warning={!submitSuccessful}>
        {submitSuccessful ? successMessage : failureMessage}
      </Message>
    </Container>
  )
})

export default RegisterWithEmail
